<template>
	<Modal class="dialog" v-model="pShown" :title="demandID ? '编辑供需' : '发布供需'" :width="800">
		<template v-if="isNotNeedPay === 0">
			<div>
				<div>抱歉，该功能需要单次充值才能继续使用，或者到个人页<span style="color: red">充值会员</span>以享受最高优惠！</div>
				<div class="pay__panel">
					<template v-if="qrCode">
						<div class="qrcode-box" ref="qrcode"></div>
						<div class="right">
							<div class="price">{{price}}</div>
							<img class="pay_logo" :src="payLogo" />
							<div class="tip">使用微信扫码支付，支付完请稍等片刻！</div>
						</div>
					</template>
					<div v-else-if="qrErrMsg">{{qrErrMsg}}</div>
					<template v-else>支付二维码加载中...</template>
				</div>
			</div>
		</template>
		<template v-else-if="hasInit">
			<div class="layout-row">
				<div class="label required">标题：</div>
				<Input v-model="info.title" type="text" maxlength="200" style="width: 300px;" placeholder="(人与物)事由+区域(省市区县)"></Input>
			</div>
			<div class="layout-row">
				<div class="label required">详情：</div>
				<vue-tinymce v-model="info.detail" :setup="onSetup" :setting="setting" style="width: 100%;">
				</vue-tinymce>
			</div>
			<!-- <div class="layout-row">
        <div class="label">封面：</div>
        <viewer>
          <img class="list__img" :src="imgSrc" @error="info.img = null" />
        </viewer>
        <Upload :show-upload-list="false" :before-upload="beforeUpload" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
          <Button icon="ios-cloud-upload-outline">添加封面</Button>
        </Upload>
      </div> -->
			<div class="layout-row" style="margin-top: 20px;">
				<div class="label required">分类：</div>
				<Select v-model="selCategory" placeholder="类别">
					<Option v-for="item in category" :value="item.id" :key="item.id">{{ item.name }}</Option>
				</Select>
				<Select v-model="selSubCate" placeholder="详别" style="margin-left:20px;">
					<Option v-for="item in subCategory" :value="item.id" :key="item.id">{{ item.name }}</Option>
				</Select>
			</div>
			<div class="layout-row">
				<div class="label">区域：</div>
				<!-- <Select v-model="selProvinceID" placeholder="省份" @on-clear="selProvinceID = selCityID = null" clearable>
          <Option v-for="item in provinces" :value="item.province_id" :key="item.province_id">{{ item.province_name }}</Option>
        </Select>
        <Select v-model="selCityID" placeholder="城市" style="margin-left:20px;" @on-clear="selProvinceID = selCityID = null" clearable>
          <Option v-for="item in optCities" :value="item.city_id" :key="item.city_id">{{ item.city_name }}</Option>
        </Select> -->
				<Cascader :data="areas" v-model="areaIds" :change-on-select="false" @on-change="areaChange"></Cascader>
			</div>
			<div class="layout-row">
				<div class="label">详细地址：</div>
				<Input v-model="info.address" type="text" maxlength="200"></Input>
			</div>
			<!-- <div class="layout-row">
        <div class="label required">联系人：</div>
        <Input v-model="info.contact"></Input>
        <div class="label required">联系电话：</div>
        <Input v-model="info.phone"></Input>
      </div>
      <div class="layout-row">
        <div class="label required">截至日期：</div>
        <Date-picker :value="info.expireDate" type="date" placeholder="选择日期" style="width: 200px" clearable @on-change="expireDateChange"></Date-picker>
      </div> -->
		</template>
		<div v-else style="position: relative; height: 400px;">
			<Spin fix>加载中...</Spin>
		</div>
		<div slot="footer">
			<!-- <Button type="default" :disabled="requesting" @click="pShown = false" size="large">取消</Button> -->
			<Button v-if="hasInit" type="primary" :disabled="requesting" @click="done"
				size="large">{{demandID ? '保存' : '提交审核'}}</Button>
		</div>
	</Modal>
</template>

<script>
	// 主题
	import 'tinymce/themes/silver'

	// 插件
	import 'tinymce/plugins/link' // 链接插件
	import 'tinymce/plugins/image' // 图片插件
	import 'tinymce/plugins/media' // 媒体插件
	import 'tinymce/plugins/table' // 表格插件
	import 'tinymce/plugins/lists' // 列表插件
	import 'tinymce/plugins/quickbars' // 快速栏插件
	import 'tinymce/plugins/fullscreen' // 全屏插件
	import 'tinymce/plugins/preview' // 预览插件

	/**
	 * 注：
	 * 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found
	 */
	import 'tinymce/icons/default/icons'

	// 本地化
	import '@/libs/tinymce/langs/zh_CN.js'

	import {
		uploadImage
	} from '@/api/common.js'
	import {
		mapMutations,
		mapGetters
	} from 'vuex'
	import {
		getInitData,
		addOrEditDemand
	} from '@/api/demand.js'
	import {
		getMemberSetting
	} from '@/api/setting.js'
	import QRCode from 'qrcodejs2'
	import {
		checkReleaseIsPaid,
		getReleasePayQr,
		checkTransaction
	} from '@/api/user'

	export default {
		name: 'AddDemand',
		model: {
			prop: 'shown',
			event: 'change'
		},
		props: {
			shown: {
				type: Boolean,
				default: false
			},
			demandInfo: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				nopic: require('@/assets/images/nopic.png'),
				hasInit: false,
				requesting: false,

				pShown: this.shown,

				/* 支付相关字段 */
				isNotNeedPay: -1,
				price: 0,
				payLogo: require('@/assets/images/wechat_pay.png'),
				qrCode: null,
				tradeNo: null,
				qrErrMsg: null,
				timers: null,

				info: {
					img: null,
					title: null,
					detail: '',
					contact: null,
					phone: null,
					expireDate: null,
					address: null
				},
				file: null,

				selCategory: null,
				selSubCate: null,
				category: null,

				provinces: null,
				selProvinceID: null,
				selCityID: null,

				areas: [],
				areaIds: [],

				setting: {
					doctype: '<!DOCTYPE html>',
					menubar: false,
					toolbar: 'undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | preview fullscreen |  fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
					quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
					plugins: 'link image media table lists preview fullscreen quickbars',
					language: 'zh_CN',
					branding: false, // 隐藏右下角技术支持，这个可以自行切换true/false看看到底是什么
					draggable_modal: false, // 模态框可拖动，这里的模态框指的是点击工具栏里面
					elementpath: false, // 隐藏元素路径，
					// max_height: 448,
					// min_height: 448,
					height: '300',
					document_base_url: this.$config.baseUrl(),
					relative_urls: true,

					content_style: `
          img                       { display:block; max-width:100%;height:auto; }'`,

					// 自定义上传图片
					async images_upload_handler(blobInfo, success, failure) {
						try {
							const res = await uploadImage(blobInfo.blob(), {
								path: 'publicity',
								compress: 'n'
							})
							success(res.content.src)
						} catch (err) {
							failure(err.message)
						}
					}
				}
			}
		},
		computed: {
			...mapGetters(['userInfo', 'hasLogin']),
			demandID() {
				if (this.demandInfo) {
					return this.demandInfo.id
				}
				return null
			},
			imgSrc() {
				if (this.info.img) {
					return this.info.img
				} else {
					return this.nopic
				}
			},
			subCategory() {
				if (this.selCategory != null) {
					const len = this.category.length
					for (let i = 0; i < len; i++) {
						if (this.selCategory == this.category[i].id) {
							return this.category[i].sub_category
						}
					}
				}
				return null
			},
			optCities() {
				if (this.selProvinceID != null) {
					const len = this.provinces.length
					for (let i = 0; i < len; i++) {
						if (this.selProvinceID == this.provinces[i].province_id) {
							return this.provinces[i].cities
						}
					}
				}
				return null
			}
		},
		watch: {
			pShown: async function(newVal) {
				this.$emit('change', newVal)
				if (newVal) {
					// 如果该用户发布需要收费，则检查是否已付费，否则弹出收费窗口
					let res = await getMemberSetting()
					switch (this.userInfo.vip_info.level) {
						case 0:
							this.price = parseInt(res.content.free.fee.release_demand)
							break
						case 1:
							this.price = parseInt(res.content.level1.fee.release_demand)
							break
						case 2:
							this.price = parseInt(res.content.level2.fee.release_demand)
							break
					}
					if (this.price > 0) {
						res = await checkReleaseIsPaid({
							release_type: 'demand'
						})
						this.isNotNeedPay = parseInt(res.content)
					} else {
						this.isNotNeedPay = 1
					}
					// console.log('价格: ' + this.price, '需要支付: ' + this.isNotNeedPay === 0 ? '是' : '否')

					if (this.isNotNeedPay === 0 && (!this.demandInfo || this.demandInfo.isAdd)) { // 需要付费且为新增
						// 获取收费二维码
						this.requestPayQr()
					} else {
						this.isNotNeedPay = 1

						if (this.demandInfo && !this.demandInfo.isAdd) {
							this.info.img = this.demandInfo.image
							this.info.title = this.demandInfo.title
							// this.info.detail = this.demandInfo.detail
							this.info.contact = this.demandInfo.contact
							this.info.phone = this.demandInfo.phone
							this.info.expireDate = this.demandInfo.expire_date
							this.info.address = this.demandInfo.address
						}

						this.initData()
					}
				} else {
					setTimeout(() => {
						Object.assign(this.$data, this.$options.data.call(this))
					}, 300)
				}
			},
			shown: function(newVal) {
				this.pShown = newVal
			},
			qrCode(newVal) {
				if (this.$refs.qrcode) {
					this.$refs.qrcode.innerHTML = ''
				}
				if (newVal != null) {
					this.$nextTick(() => {
						this.showQRcode()
					})
				}
			}
		},
		methods: {
			/* 支付 开始 */
			showQRcode() {
				const _ = new QRCode(this.$refs.qrcode, {
					text: this.qrCode, // 需要转换为二维码的内容
					width: 160,
					height: 160,
					colorDark: '#000',
					colorLight: '#fff',
					correctLevel: QRCode.CorrectLevel.H
				})

				if (this.timers) {
					clearInterval(this.timers)
				}

				this.timers = setInterval(async () => {
					await this.getOrderState()
				}, 3000)
			},
			async requestPayQr() {
				this.qrErrMsg = null
				try {
					const params = {
						release_type: 'demand'
					}
					const res = await getReleasePayQr(params)
					this.tradeNo = res.content.trade_no
					this.qrCode = res.content.code_url
				} catch (error) {
					this.$Message.error(error.message)
					this.qrCode = null
					this.qrErrMsg = error.message
				}
			},
			async getOrderState() {
				if (this.tradeNo == null) return
				try {
					const params = {
						trade_no: this.tradeNo
					}
					await checkTransaction(params)
					this.$Message.success('支付成功')
					if (this.timers) {
						clearInterval(this.timers)
					}
					this.isNotNeedPay = 1
					this.initData()
				} catch (error) {}
			},
			/* 支付 结束 */
			onSetup(editor) {
				const _this = this
				editor.on('init', function(e) {
					console.log('初始化完成')
					_this.$nextTick(() => {
						if (_this.demandInfo && !_this.demandInfo.isAdd) {
							_this.info.detail = _this.demandInfo.detail
						}
					})
				})
			},
			expireDateChange(date) {
				this.info.expireDate = date
				// console.log(date, this.info.expireDate)
			},
			async initData() {
				try {
					const res = await getInitData()

					this.selProvinceID = null
					this.selCityID = null

					this.category = res.content.category
					this.provinces = res.content.provinces

					const len = this.provinces.length
					for (let i = 0; i < len; i++) {
						const province = {
							label: this.provinces[i].province_name,
							value: this.provinces[i].province_id,
							children: []
						}
						this.areas.push(province)
						const cities = this.provinces[i].cities
						const cLen = cities.length
						for (let j = 0; j < cLen; j++) {
							province.children.push({
								label: cities[j].city_name,
								value: cities[j].city_id
							})
						}
					}

					if (this.demandInfo) {
						if (this.demandInfo.city_id != null) {
							const len = this.provinces.length
							for (let i = 0; i < len; i++) {
								const cities = this.provinces[i].cities
								const cLen = cities.length
								for (let j = 0; j < cLen; j++) {
									if (cities[j].city_id == this.demandInfo.city_id) {
										this.selProvinceID = cities[j].province_id
										this.selCityID = cities[j].city_id
										this.areaIds = [this.selProvinceID, this.selCityID]
										break
									}
								}
								if (this.selProvinceID != null) break
							}
						}

						if (this.demandInfo.c_id != null) {
							const len = this.category.length
							for (let i = 0; i < len; i++) {
								if (this.category[i].id == this.demandInfo.c_id) {
									this.selCategory = this.category[i].id
									break
								}

								const subCates = this.category[i].sub_category
								const cLen = subCates.length
								for (let j = 0; j < cLen; j++) {
									if (subCates[j].id == this.demandInfo.c_id) {
										this.selCategory = subCates[j].pid
										this.selSubCate = subCates[j].id
										break
									}
								}
								if (this.selCategory != null) break
							}
						}
					}
					this.hasInit = true
				} catch (error) {
					this.$Message.error(error.message)
				}
			},
			areaChange(e) {
				if (e.length === 2) {
					this.selCityID = e[1]
				}
			},
			beforeUpload(file) {
				// 创建一个 FileReader 对象
				const reader = new FileReader()
				// readAsDataURL 方法用于读取指定 Blob 或 File 的内容
				// 当读操作完成，readyState 变为 DONE，loadend 被触发，此时 result 属性包含数据：URL（以 base64 编码的字符串表示文件的数据）
				// 读取文件作为 URL 可访问地址
				reader.readAsDataURL(file)

				reader.onloadend = e => {
					this.info.img = reader.result
					this.file = file
				}
				return false
			},
			async done() {
				let category_id = 0
				if (this.selSubCate) {
					category_id = this.selSubCate
				} else if (this.selCategory) {
					category_id = this.selCategory
				}

				// if (this.selCityID == null) {
				//   this.$Message.error('请选择区域')
				//   return
				// }

				if (this.info.title == null || this.info.title.length == 0) {
					this.$Message.error('请输入标题')
					return
				}

				if (this.info.detail == null || this.info.detail.length == 0) {
					this.$Message.error('请输入详情')
					return
				}

				/* if (this.info.contact == null || this.info.contact.length == 0) {
				  this.$Message.error('联系人不能为空')
				  return
				}

				if (this.info.phone == null || this.info.phone.length == 0) {
				  this.$Message.error('联系电话不能为空')
				  return
				} */

				this.$Spin.show()

				// 总是添加 width: 100%; height: auto; 样式
				let detail = this.info.detail.replace(/<img src="/gi, '<img style="width: 100%; height: auto;" src="')
				detail = detail.replace(/<img style="(?!width:)/gi, '<img style="width: 100%; height: auto; ')

				const params = {
					category_id,
					city_id: this.selCityID ? this.selCityID : null,
					title: this.info.title,
					detail: detail,
					contact: this.info.contact,
					phone: this.info.phone,
					expire_date: this.info.expireDate,
					address: this.info.address
				}
				if (this.demandID) {
					params.id = this.demandID
				}

				const fileDes = this.file ? {
					name: 'list_img',
					file: this.file
				} : {}

				this.requesting = true

				try {
					const res = await addOrEditDemand(fileDes, params)
					const detail = {
						demandInfo: res.content
					}
					if (this.demandID) {
						detail.isEdit = true
						detail.index = this.demandInfo.index
						this.$Message.success('保存成功')
					} else {
						this.$Message.success('发布成功')
					}
					this.$emit('on-success', detail)

					this.$Spin.hide()
					this.pShown = false
				} catch (err) {
					this.$Spin.hide()
					this.$Message.error(err.message)
				}

				this.requesting = false
			},


			/**
			 * @param {Object} pois位置搜索
			 */
			onSearchResult(pois) {
				//搜索
				console.log(pois)
				let latSum = 0
				let lngSum = 0
				let that = this
				if (pois && pois.length > 0) {
					//如果长度为1则无需转化
					let poi = pois[0]
					let lng = poi["lng"]
					let lat = poi["lat"]
					that.center = [lng, lat]
					that.zoom = 13
					that.content = poi.name
					console.log(poi.name)
					that.searchResult.address = poi.address
					that.searchResult.latitude = poi.lat
					that.searchResult.longitude = poi.lng
					let geocoder = new AMap.Geocoder({})
					geocoder.getAddress(that.center, function(status, result) {
						console.log(result)
						if (status === 'complete' && result.info === 'OK') {
							let obj = result.regeocode.addressComponent
							that.searchResult.locationName = obj.province + obj.city + obj.district + obj
								.township + obj.street +
								poi.address
						}
					});
				} else {
					that.searchResult = []
				}
			},
			
			
		}
	}
</script>

<style lang="less" scoped>
	// 样式
	@import '~tinymce/skins/ui/oxide/skin.min.css';
	@import '~tinymce/skins/ui/oxide/content.min.css';

	.dialog {
		::v-deep .ivu-modal-body {
			padding: 16px 40px 16px 16px;
		}
	}

	.list__img {
		display: block;
		width: 80px;
		height: 80px;
		border-radius: 5px;
		border: 1px solid #ebebeb;
		margin-right: 20px;
		background-color: #ddd;
		object-fit: cover;
	}

	.layout-row {
		margin-bottom: 15px;
	}

	.label {
		width: 100px;
		text-align: right;
		margin-right: 10px;
		flex: 0 0 auto;

		&.required::before {
			content: '*';
			display: inline-block;
			margin-right: 4px;
			line-height: 1;
			font-family: SimSun;
			font-size: 14px;
			color: #ed4014;
		}
	}

	.pay__panel {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;

		.right {
			display: flex;
			height: 160px;
			flex-direction: column;
			justify-content: center;
			margin-left: 20px;

			.price {
				color: #ff3a3a;
				text-align: center;
				font-size: 40px;
				line-height: 60px;
				font-weight: 500;

				&::after {
					content: " 元";
					font-size: 14px;
				}
			}

			.pay_logo {
				flex-shrink: 0;
				width: 120px;
				height: 40px;
				object-fit: contain;
			}

			.tip {
				line-height: 40px;
				font-size: 15px;
			}
		}

		.err {
			text-align: center;
		}
	}

	.qrcode-box {
		display: inline-block;
		width: 160px;
		height: 160px;
	}
</style>
