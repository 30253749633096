 <template>
  <Modal class="dialog" v-model="pShown" :mask-closable="false" title="个人设置" :width="800">
    <Tabs type="card" :animated="false" v-model="tabIndex">
      <TabPane name="1" label="基本信息">
        <Form ref="info" :model="info" :rules="infoRules" :label-width="100" style="margin:0 10px;">
          <FormItem label="头像" prop="avatar">
            <div style="display: inline-block; padding-right: 10px;">
              <viewer v-if="avatarUrl">
                <img class="u-avatar" :src="avatarUrl" alt="">
              </viewer>
              <Avatar v-else class="u-avatar" shape="square" icon="ios-person" size="64" :src="avatarUrl" style="margin-right: 20px;"/>
            </div>
            <Upload :show-upload-list="false" :before-upload="beforeUpload" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
              <Button icon="ios-cloud-upload-outline">上传头像</Button>
            </Upload>
          </FormItem>
          <FormItem label="姓名" prop="realName" key="realName">
            <Input v-model="info.realName"></Input>
          </FormItem>
          <FormItem label="手机号" prop="mobile" key="mobile">
            <Input v-model="info.mobile"></Input>
          </FormItem>
          <FormItem label="所在城市" required>
            <div class="layout-row">
              <!-- <Select v-model="selProvinceID" placeholder="省份">
                <Option v-for="item in provinces" :value="item.province_id" :key="item.province_id">{{ item.province_name }}</Option>
              </Select>
              <Select v-model="selCityID" placeholder="城市" style="margin-left:20px">
                <Option v-for="item in optCities" :value="item.city_id" :key="item.city_id">{{ item.city_name }}</Option>
              </Select> -->
              <Cascader :data="areas" v-model="areaIds" :change-on-select="false" @on-change="areaChange"></Cascader>
            </div>
          </FormItem>
          <FormItem label="电子邮箱" prop="email" key="email">
            <Input v-model="info.email"></Input>
          </FormItem>
          <FormItem label="行业" prop="industry" key="industry">
            <Input v-model="info.industry"></Input>
          </FormItem>
          <FormItem label="性别" prop="gender">
            <RadioGroup v-model="info.gender">
              <Radio :label="1" style="margin-right:20px;">男</Radio>
              <Radio :label="2">女</Radio>
            </RadioGroup>
          </FormItem>
        </Form>
      </TabPane>
      <TabPane name="4" label="企业认证">
        <Form ref="info" :model="info" :rules="infoRules" :label-width="100" style="margin:0 10px;">
          <FormItem label="状态" key="is_company_authentication">
            <div style="color: green;" v-if="userInfo.is_company_authentication === 1">认证成功</div>
            <div style="color: red;" v-else>未认证（请上传营业执照核验）</div>
          </FormItem>
          <FormItem label="营业执照" prop="businessLicense">
            <div style="display: inline-block; padding-right: 10px;">
              <viewer v-if="userInfo.business_license_path">
                <img class="u-avatar" :src="userInfo.business_license_path" alt="">
              </viewer>
              <img v-else class="u-avatar" :src="nopic" alt="">
            </div>
            <Upload :show-upload-list="false" :before-upload="uploadBusinessLicense" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
              <Button icon="ios-cloud-upload-outline">{{ userInfo.is_company_authentication === 1 ? '重新上传认证' : '上传营业执照'}}</Button>
            </Upload>
          </FormItem>
          <FormItem label="公司名称" prop="company" key="company">
            <Input v-model="info.company" :disabled="true"></Input>
          </FormItem>
          <FormItem label="统一社会信用代码" prop="credit_code" key="credit_code">
            <Input v-model="info.credit_code" :disabled="true"></Input>
          </FormItem>
          <FormItem v-show="false" label="工商注册号" prop="regno" key="regno">
            <Input v-model="info.regno" :disabled="true"></Input>
          </FormItem>
          <FormItem label="地址" prop="dom" key="dom">
            <Input v-model="info.dom" :disabled="true"></Input>
          </FormItem>
          <FormItem v-show="false" label="经营业务范围" prop="zsopscope" key="zsopscope">
            <Input v-model="info.zsopscope" :disabled="true"></Input>
          </FormItem>
        </Form>
      </TabPane>
      <TabPane name="5" label="资料设置">
        <Form ref="info" :model="info" :rules="infoRules" :label-width="100" style="margin:0 10px;">
          <!-- 电子章 -->
          <FormItem label="电子章" style="margin-bottom: 0;">
            <Button type="primary" ghost @click="addSeal">+ 增加电子章</Button>
          </FormItem>
          <FormItem label="" style="margin-bottom: 0;">
            <div style="color: red;">注：电子章必须上传无背景色的png格式图片</div>
          </FormItem>
          <!-- 已保存电子章 -->
          <div>
            <div class="layout-row" v-for="(seal, sealIndex) in userInfo.user_and_seals" :key="sealIndex">
              <FormItem :label="(sealIndex + 1) + '、'" prop="realName" key="realName" style="margin: auto 0;">
                <Select v-model="seal.seal_no" placeholder="盖章方" style="width: 300px;" :disabled="true">
                  <OptionGroup v-for="(unit, unitIndex) in seal_list" :key="unitIndex" :label="unit.unit_name">
                    <Option v-for="sealOption in unit.seal_list_show" :key="sealOption.seal_no" :value="sealOption.seal_no">{{ sealOption.seal_name }}</Option>
                  </OptionGroup>
                </Select>
              </FormItem>
              <div style="display: inline-block; padding-right: 10px; padding-left: 10px;">
                <viewer v-if="seal.electronic_seal_path">
                  <img class="u-avatar" :src="seal.electronic_seal_path" alt="">
                </viewer>
                <img v-else class="u-avatar" :src="nopic" alt="">
              </div>
              <Button type="error" ghost style="margin-left: 10px;" @click="deleteSeal(seal, sealIndex)">删除</Button>
            </div>
          </div>
          <!-- 未保存电子章 -->
          <div>
            <div class="layout-row" v-for="(seal, sealIndex) in user_and_seals" :key="sealIndex">
              <FormItem :label="(sealIndex + 1 + userInfo.user_and_seals.length) + '、'" prop="realName" key="realName" style="margin: auto 0;">
                <Select v-model="seal.seal_no" placeholder="盖章方" style="width: 300px;" @on-change="sealChange($event, seal)" :disabled="seal_files.findIndex(item => item.name === seal.seal_no) !== -1">
                  <OptionGroup v-for="(unit, unitIndex) in seal_list" :key="unitIndex" :label="unit.unit_name">
                    <Option v-for="sealOption in unit.seal_list" :key="sealOption.seal_no" :value="sealOption.seal_no">{{ sealOption.seal_name }}</Option>
                  </OptionGroup>
                </Select>
              </FormItem>
              <div style="display: inline-block; padding-right: 10px; padding-left: 10px;">
                <viewer v-if="seal.src">
                  <img class="u-avatar" :src="seal.src" alt="">
                </viewer>
                <img v-else class="u-avatar" :src="nopic" alt="">
              </div>
              <Upload :show-upload-list="false" :before-upload="(file) => uploadSeal(file, seal.seal_no)" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
                <Button icon="ios-cloud-upload-outline">上传电子章</Button>
              </Upload>
              <Button type="error" ghost style="margin-left: 10px;" @click="deleteSeal(seal, sealIndex)">删除</Button>
            </div>
          </div>
          <!-- 签名方 -->
          <FormItem label="签名方" style="margin-bottom: 10px; margin-top: 20px;">
            <Button type="primary" ghost @click="addSigner">+ 增加签名方</Button>
          </FormItem>
          <!-- 已保存签名方 -->
          <div>
            <div class="layout-row" v-for="(signer, signerIndex) in userInfo.user_and_signers" :key="signerIndex" style="padding-bottom: 10px;">
              <FormItem :label="(signerIndex + 1) + '、'" prop="realName" key="realName" style="margin: auto 0;">
                <Select v-model="signer.signer_no" placeholder="签名方" style="width: 300px;" :disabled="true">
                  <OptionGroup v-for="(unit, unitIndex) in sign_list" :key="unitIndex" :label="unit.unit_name">
                    <Option v-for="signerOption in unit.sign_list_show" :key="signerOption.signer_no" :value="signerOption.signer_no">{{ signerOption.signer }}</Option>
                  </OptionGroup>
                </Select>
              </FormItem>
              <Button type="error" ghost style="margin-left: 10px;" @click="deleteSigner(signer, signerIndex)">删除</Button>
            </div>
          </div>
          <!-- 未保存签名方 -->
          <div>
            <div class="layout-row" v-for="(signer, signerIndex) in user_and_signers" :key="signerIndex" style="padding-bottom: 10px;">
              <FormItem :label="(signerIndex + 1 + userInfo.user_and_signers.length) + '、'" prop="realName" key="realName" style="margin: auto 0;">
                <Select v-model="signer.signer_no" placeholder="签名方" style="width: 300px;" @on-change="signerChange($event, signer)">
                  <OptionGroup v-for="(unit, unitIndex) in sign_list" :key="unitIndex" :label="unit.unit_name">
                    <Option v-for="signerOption in unit.sign_list" :key="signerOption.signer_no" :value="signerOption.signer_no">{{ signerOption.signer }}</Option>
                  </OptionGroup>
                </Select>
              </FormItem>
              <Button type="error" ghost style="margin-left: 10px;" @click="deleteSigner(signer, signerIndex)">删除</Button>
            </div>
          </div>
        </Form>
      </TabPane>
      <TabPane name="2" label="安全设置">
        <Form ref="security" :model="security" :rules="secRules" :label-width="100" style="margin:0 10px;">
          <FormItem label="旧密码" prop="oldPasswd" key="oldPasswd">
            <Input v-model="security.oldPasswd" type="password"></Input>
          </FormItem>
          <FormItem label="新密码" prop="newPasswd" key="newPasswd">
            <Input v-model="security.newPasswd" type="password"></Input>
          </FormItem>
          <FormItem label="确认密码" prop="checkPasswd" key="checkPasswd">
            <Input v-model="security.checkPasswd" type="password"></Input>
          </FormItem>
        </Form>
      </TabPane>
      <TabPane name="3" label="收款二维码">
        <div class="layout-row" style="justify-content: center;">
          <img class="qr-img" :src="qrcodeUrl" @error="qrcode.url = nopic"/>
          <Upload :show-upload-list="false" :before-upload="uploadQrCode" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
            <Button icon="ios-cloud-upload-outline">上传收款二维码</Button>
          </Upload>
        </div>
      </TabPane>
    </Tabs>
    <div slot="footer">
      <Button type="default" :disabled="loading" @click="pShown = false">取消</Button>
      <Button v-if="!['4'].includes(tabIndex)" type="primary" :disabled="loading" @click="done">保存</Button>
    </div>

    <ImageCropper
      v-model="cropOption.showCropper"
      :fileName="cropOption.fileName"
      :name="cropOption.name"
      :cropImg="cropOption.cropImg"
      :fixWidthRatio="cropOption.fixWidthRatio"
      :fixHeightRatio="cropOption.fixHeightRatio"
      @cropSuccess="cropSuccess"
    />
  </Modal>
</template>

<script>
import {
  modifyUserInfo, verificationBusinessLicense, changePassword, modifyUserQrCode,
  getSealAndSignerOptions, saveSealAndSigners, deleteSeal, deleteSigner
} from '@/api/user.js'
import { getRegionData } from '@/api/city.js'
import { mapActions, mapGetters } from 'vuex'
import ImageCropper from '@/components/ImageCropper.vue'

export default {
  name: 'EditUserInfo',
  components: {
    ImageCropper
  },
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    tabName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      nopic: require('@/assets/images/nopic.png'),

      loading: false,
      pShown: false,

      tabIndex: '1',
      info: {
        realName: '',
        mobile: '',
        email: '',
        company: '',
        industry: '',
        gender: 1
      },
      avatarSrc: null,
      file: null,

      security: {
        oldPasswd: '',
        newPasswd: '',
        checkPasswd: ''
      },

      selProvinceID: null,
      selCityID: null,
      provinces: null,

      areas: [],
      areaIds: [],

      qrcode: {
        url: null,
        file: null
      },

      seal_list: [],
      sign_list: [],
      user_and_seals: [], // { sys_user_id, unit_id, seal_no, seal_no, src }
      seal_files: [], // { name: 同seal_no, file }
      user_and_signers: [], // { sys_user_id, unit_id, signer_no, signer }

      /* 图像裁剪 */
      cropOption: {
        showCropper: false,
        fileName: '',
        name: '',
        cropImg: null,
        fixWidthRatio: 1,
        fixHeightRatio: 1
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    optCities() {
      if (this.selProvinceID != null) {
        const len = this.provinces.length
        for (let i = 0; i < len; i++) {
          if (this.selProvinceID == this.provinces[i].province_id) {
            return this.provinces[i].cities
          }
        }
      }
      return null
    },
    infoRules() {
      return {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }]
      }
    },
    secRules() {
      return {
        oldPasswd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPasswd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        checkPasswd: [{ required: true, message: '确认新密码', trigger: 'blur' }]
      }
    },
    avatarUrl() {
      if (this.avatarSrc) {
        return this.avatarSrc
      } else {
        return this.userInfo.avatar_url
      }
    },
    qrcodeUrl() {
      if (this.qrcode.url) {
        return this.qrcode.url
      } else {
        return this.userInfo.qrcode
      }
    }
  },
  watch: {
    pShown(newVal) {
      this.$emit('change', newVal)
      this.tabIndex = this.tabName === null ? '1' : this.tabName
      if (newVal) {
        this.info.realName = this.userInfo.real_name
        this.info.mobile = this.userInfo.mobile
        this.info.email = this.userInfo.email
        this.info.industry = this.userInfo.industry
        this.info.gender = parseInt(this.userInfo.gender)
        this.info.company = this.userInfo.company
        this.info.credit_code = this.userInfo.credit_code
        this.info.regno = this.userInfo.regno
        this.info.zsopscope = this.userInfo.zsopscope
        this.info.dom = this.userInfo.dom

        getRegionData().then(res => {
          this.provinces = res.content.provinces
          // this.selProvinceID = this.userInfo.province_id
          // this.selCityID = this.userInfo.city_id

          this.selProvinceID = null
          this.selCityID = null

          const len = this.provinces.length
          for (let i = 0; i < len; i++) {
            const province = { label: this.provinces[i].province_name, value: this.provinces[i].province_id, children: [] }
            this.areas.push(province)
            const cities = this.provinces[i].cities
            const cLen = cities.length
            for (let j = 0; j < cLen; j++) {
              province.children.push({ label: cities[j].city_name, value: cities[j].city_id })
            }
          }

          if (this.userInfo.city_id != null) {
            const len = this.provinces.length
            for (let i = 0; i < len; i++) {
              const cities = this.provinces[i].cities
              const cLen = cities.length
              for (let j = 0; j < cLen; j++) {
                if (cities[j].city_id == this.userInfo.city_id) {
                  this.selProvinceID = cities[j].province_id
                  this.selCityID = cities[j].city_id
                  this.areaIds = [this.selProvinceID, this.selCityID]
                  break
                }
              }
              if (this.selProvinceID != null) break
            }
          }
        }).catch((err) => {
          this.$Message.error(err.message)
        })
      } else {
        setTimeout(() => {
          if (this.$refs.info) {
            this.$refs.info.resetFields()
          }
          if (this.$refs.security) {
            this.$refs.security.resetFields()
          }
          Object.assign(this.$data, this.$options.data.call(this))
        }, 300)
      }
    },
    shown(newVal) {
      this.pShown = newVal
    }
  },
  async mounted() {
    await this.getSealAndSignerOptions()
    this.initContractSetting()
  },
  methods: {
    ...mapActions(['getUserInfo']),
    areaChange(e) {
      if (e.length === 2) {
        this.selCityID = e[1]
      }
    },
    beforeUpload(file) {
      // 创建一个 FileReader 对象
      const reader = new FileReader()
      // readAsDataURL 方法用于读取指定 Blob 或 File 的内容
      // 当读操作完成，readyState 变为 DONE，loadend 被触发，此时 result 属性包含数据：URL（以 base64 编码的字符串表示文件的数据）
      // 读取文件作为 URL 可访问地址
      reader.readAsDataURL(file)

      reader.onloadend = e => {
        // this.avatarSrc = reader.result
        // this.file = file

        this.cropOption.fileName = file.name
        this.cropOption.name = 'avatar'
        this.cropOption.cropImg = reader.result
        this.cropOption.fixWidthRatio = 1
        this.cropOption.fixHeightRatio = 1
        this.cropOption.showCropper = true
      }
      return false
    },
    uploadQrCode(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = e => {
        // this.qrcode.url = reader.result
        // this.qrcode.file = file

        this.cropOption.fileName = file.name
        this.cropOption.name = 'qrcode'
        this.cropOption.cropImg = reader.result
        this.cropOption.fixWidthRatio = 1
        this.cropOption.fixHeightRatio = 1
        this.cropOption.showCropper = true
      }
      return false
    },
    uploadBusinessLicense(file) {
      // const reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onloadend = e => {
      //   this.qrcode.url = reader.result
      //   this.qrcode.file = file
      // }
      this.loading = true
      this.$Spin.show()

      const fileDes = []
      fileDes.push({name: 'business_license', file})
      verificationBusinessLicense(fileDes).then((res) => {
        this.userInfo.business_license_path = res.content.business_license_path
        this.userInfo.company = res.content.company
        this.userInfo.regno = res.content.regno
        this.userInfo.zsopscope = res.content.zsopscope
        this.userInfo.dom = res.content.dom
        this.userInfo.is_company_authentication = res.content.is_company_authentication
        this.info = Object.assign({}, this.userInfo)
        this.$store.commit('setUserInfo', this.userInfo)
        // this.pShown = false
        this.$Message.success('核验成功')
        this.loading = false
        this.$Spin.hide()
      }).catch((err) => {
        this.$Message.error(err.message)
        this.loading = false
        this.$Spin.hide()
      })
      return false
    },
    /* 资料设置 开始 */
    async getSealAndSignerOptions() {
      const res = await getSealAndSignerOptions()
      this.seal_list = res.content.seal_list
      this.sign_list = res.content.sign_list
    },
    initContractSetting() {
      this.user_and_seals = []
      if (this.userInfo.user_and_seals.length === 0) {
        this.addSeal()
      }
      this.user_and_signers = []
      if (this.userInfo.user_and_signers.length === 0) {
        this.addSigner()
      }
    },
    uploadSeal(file, seal_no) {
      if (seal_no) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = e => {
          const user_and_seal = this.user_and_seals.find(item => item.seal_no === seal_no)
          if (user_and_seal) {
            user_and_seal.src = reader.result
          }
          const seal_file = this.seal_files.find(item => item.name === seal_no)
          if (seal_file) {
            seal_file.file = file
          } else {
            this.seal_files.push({
              name: seal_no,
              file: file
            })
          }
        }
      } else {
        this.$Message.error('请先选择盖章方')
      }
      return false
    },
    addSeal() {
      this.user_and_seals.push({
        sys_user_id: this.userInfo.id,
        unit_id: null,
        seal_no: null,
        seal_name: null,

        src: null
      })
    },
    async deleteSeal(seal, sealIndex) {
      if (seal.id) {
        this.$Modal.confirm({
          title: '提示',
          content: '确定删除吗？',
          onOk: async () => {
            this.loading = true
            this.$Spin.show()
            try {
              await deleteSeal({ id: seal.id })
              this.userInfo.user_and_seals.splice(sealIndex, 1)
              this.$store.commit('setUserInfo', this.userInfo)
              this.initContractSetting()
              this.loading = false
              this.$Spin.hide()
            } catch (err) {
              this.loading = false
              this.$Spin.hide()
            }
          }
        })
      } else {
        this.user_and_seals.splice(sealIndex, 1)
      }
    },
    sealChange(e, seal) {
      for (let i = 0; i < this.seal_list.length; i++) {
        const unit = this.seal_list[i]
        const sealOption = unit.seal_list.find(item => item.seal_no === e)
        if (sealOption) {
          seal.unit_id = sealOption.unit_id
          seal.seal_name = sealOption.seal_name
          break
        }
      }
      console.log(e, seal)
    },
    addSigner() {
      this.user_and_signers.push({
        sys_user_id: this.userInfo.id,
        unit_id: null,
        signer_no: null,
        signer: null
      })
    },
    async deleteSigner(signer, signerIndex) {
      if (signer.id) {
        this.$Modal.confirm({
          title: '提示',
          content: '确定删除吗？',
          onOk: async () => {
            this.loading = true
            this.$Spin.show()
            try {
              await deleteSigner({ id: signer.id })
              this.userInfo.user_and_signers.splice(signerIndex, 1)
              this.$store.commit('setUserInfo', this.userInfo)
              this.initContractSetting()
              this.loading = false
              this.$Spin.hide()
            } catch (err) {
              this.loading = false
              this.$Spin.hide()
            }
          }
        })
      } else {
        this.user_and_signers.splice(signerIndex, 1)
      }
    },
    signerChange(e, signer) {
      for (let i = 0; i < this.sign_list.length; i++) {
        const unit = this.sign_list[i]
        const signerOption = unit.sign_list.find(item => item.signer_no === e)
        if (signerOption) {
          signer.unit_id = signerOption.unit_id
          signer.signer = signerOption.signer
          break
        }
      }
      console.log(e, signer)
    },
    /* 资料设置 结束 */
    // 裁剪成功回调
    cropSuccess(imageInfo) {
      console.log(imageInfo)
      const file = new window.File([imageInfo.blob], imageInfo.fileName) // Blob对象转File对象
      switch (imageInfo.name) {
        case 'avatar':
          this.file = file
          this.avatarSrc = imageInfo.base64
          break
        case 'qrcode':
          this.qrcode.file = file
          this.qrcode.url = imageInfo.base64
          break
      }
    },
    // 保存
    done() {
      this.$Spin.show()
      if (this.tabIndex == '1') {
        this.$refs.info.validate((valid) => {
          if (!valid) {
            return
          }
          if (this.selCityID == null) {
            this.$Message.error('请选择所在城市')
            return
          }

          const params = {
            real_name: this.info.realName,
            mobile: this.info.mobile,
            email: this.info.email,
            company: this.info.company,
            industry: this.info.industry,
            gender: this.info.gender,
            city_id: this.selCityID
          }

          const fileDes = []
          if (this.file) {
            // 头像
            fileDes.push({name: 'avatar', file: this.file})
          } else if (this.qrcode.file) {
            // 二维码
            fileDes.push({name: 'qrcode', file: this.qrcode.file})
          }

          this.loading = true
          modifyUserInfo(fileDes, params).then((res) => {
            const userInfo = Object.assign({}, this.userInfo, res.content)
            this.$store.commit('setUserInfo', userInfo)
            this.pShown = false
            this.$Message.success('保存成功')
            this.loading = false
          }).catch((err) => {
            this.$Spin.hide()
            this.$Message.error(err.message)
            this.loading = false
          })
        })
      } else if (this.tabIndex == '2') {
        this.$refs.security.validate((valid) => {
          if (!valid) {
            return
          }

          if (this.security.newPasswd != this.security.checkPasswd) {
            this.$Message.error('确认密码不一致，请重新输入')
            return
          }
          this.loading = true
          changePassword(this.security.oldPasswd, this.security.newPasswd).then(() => {
            this.$store.commit('setToken', null)
            this.$store.commit('setUserInfo', {})
            this.pShown = false
            this.loading = false
            this.$Message.success('修改成功，请重新登录')
            this.$router.replace({ name: this.$config.homeName})
          }).catch((err) => {
            this.$Spin.hide()
            this.$Message.error(err.message)
            this.loading = false
          })
        })
      } else if (this.tabIndex == '3') {
        this.loading = true

        const fileDes = []
        if (this.qrcode.file) {
          // 二维码
          fileDes.push({name: 'qrcode', file: this.qrcode.file})
        }
        modifyUserQrCode(fileDes).then((res) => {
          const userInfo = Object.assign({}, this.userInfo, res.content)
          this.$store.commit('setUserInfo', userInfo)
          this.pShown = false
          this.$Message.success('保存成功')
          this.loading = false
        }).catch((err) => {
          this.$Spin.hide()
          this.$Message.error(err.message)
          this.loading = false
        })
      } else if (this.tabIndex == '4') { // 企业认证
      } else if (this.tabIndex == '5') { // 资料设置
        /* 校验 */
        let bRet = true
        let message = ''

        do {
          // 假如盖章方已选择，文件未选择，提示
          for (let i = 0; i < this.user_and_seals.length; i++) {
            const user_and_seal = this.user_and_seals[i]
            if (user_and_seal.seal_no !== null && !this.seal_files.find(item => item.name === user_and_seal.seal_no && item.file !== null)) {
              bRet = false
              message = '请上传“' + user_and_seal.seal_name + '”的电子章'
              break
            }
          }
          if (!bRet) break
        } while (false)

        if (bRet) {
          this.loading = true
          const fileDes = []
          for (let i = 0; i < this.seal_files.length; i++) {
            if (this.seal_files[i].file !== null) {
              fileDes.push(this.seal_files[i])
            }
          }
          const user_and_seals = this.user_and_seals.filter(item => item.seal_no !== null && item.file !== null)
          user_and_seals.forEach(item => {
            item.src = undefined
          })
          const user_and_signers = this.user_and_signers.filter(item => item.signer_no !== null)
          saveSealAndSigners(fileDes, { user_and_seals: JSON.stringify(user_and_seals), user_and_signers: JSON.stringify(user_and_signers) }).then((res) => {
            this.userInfo.user_and_seals = res.content.user_and_seals
            this.userInfo.user_and_signers = res.content.user_and_signers
            this.$store.commit('setUserInfo', this.userInfo)
            this.initContractSetting()
            this.getSealAndSignerOptions()
            this.$Message.success('保存成功')
            this.loading = false
            this.$Spin.hide()
          }).catch((err) => {
            this.$Message.error(err.message)
            this.loading = false
            this.$Spin.hide()
          })
        } else {
          this.$Message.error(message)
        }
      }
      this.$Spin.hide()
    }
  }
}
</script>

<style lang="less" scoped>
.dialog ::v-deep .ivu-modal-body {
  padding: 5px 30px 16px;
  min-height: 500px;
}
/deep/.ivu-tabs {
  overflow: unset !important;
}

.u-avatar {
  width: 60px;
  height: 60px;
  border-radius: 5px;

  ::v-deep img {
    object-fit: cover;
  }
}

.qr-img {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 5px;
  background-color: #eee;
  margin-right: 20px;
  object-fit: cover;
}

</style>
