<template>
  <Modal class="dialog" v-model="pShown" :title="'图片裁剪'" :width="800">
    <div class="cropper-content">
      <div class="cropper-box">
        <div class="cropper">
          <vue-cropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.outputSize"
              :outputType="option.outputType"
              :info="option.info"
              :canScale="option.canScale"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :full="option.full"
              :fixedBox="option.fixedBox"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :centerBox="option.centerBox"
              :height="option.height"
              :infoTrue="option.infoTrue"
              :maxImgSize="option.maxImgSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              @realTime="realTime"
              @imgLoad="imgLoad">
          </vue-cropper>
        </div>
        <!--底部操作工具按钮-->
        <div class="footer-btn">
          <div class="scope-btn">
            <!-- <label class="label-btn" for="uploads">选择封面</label>
            <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)"> -->
            <Button class="btn" type="primary" plain @click="changeScale(1)">放大</Button><!-- size="mini" icon="el-icon-zoom-in" -->
            <Button class="btn" type="primary" plain @click="changeScale(-1)">缩小</Button><!-- size="mini" icon="el-icon-zoom-out" -->
            <Button class="btn" type="primary" plain @click="rotateLeft">↺ 左旋转</Button><!-- size="mini" -->
            <Button class="btn" type="primary" plain @click="rotateRight">↻ 右旋转</Button><!-- size="mini" -->
          </div>
        </div>
      </div>
      <!--预览效果图-->
      <div class="show-preview">
        <div :style="previews.div" class="preview">
          <img :src="previews.url" :style="previews.img">
        </div>
        <div class="result-title">裁剪结果</div>
      </div>
    </div>
    <div slot="footer">
      <!-- <Button type="default" :disabled="requesting" @click="pShown = false" size="large">取消</Button> -->
      <Button type="success" @click="handleCropImg()">确定裁剪 <i class="el-icon-upload"></i></Button><!-- size="mini" -->
    </div>
  </Modal>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  name: 'ImageCropper',
  components: {
    VueCropper
  },
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: true
    },
    cropImg: {
      type: String,
      require: true
    },
    fixWidthRatio: {
      type: Number,
      default: 1
    },
    fixHeightRatio: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      pShown: this.shown,
      previews: {},
      option: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png', // 裁剪生成图片的格式（jpeg || png || webp）
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // false按原比例裁切图片，不失真
        fixedBox: false, // 固定截图框大小，不允许改变
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: '230px 150px' // 图片默认渲染方式
      }
    }
  },
  watch: {
    shown: function (newVal) {
      this.pShown = newVal
      if (newVal) {
        this.option.img = this.cropImg
        this.option.fixedNumber = [this.fixWidthRatio, this.fixHeightRatio]
      }
    },
    pShown: function (newVal) {
      this.$emit('change', newVal)
    }
  },
  methods: {
    // 初始化函数
    imgLoad(msg) {
      console.log('工具初始化函数=====' + msg)
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data
    },
    // 选择图片
    /* selectImg(e) {
      const file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: 'error'
        })
        return false
      }
      // 转化为blob
      const reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      // 转化为base64
      reader.readAsDataURL(file)
    }, */
    // 上传图片
    handleCropImg() {
      this.$refs.cropper.getCropBlob(async (data) => {
        const imgInfo = {
          fileName: this.fileName,
          name: this.name,
          blob: data,
          base64: null
        }
        this.$refs.cropper.getCropData((data2) => {
          imgInfo.base64 = data2
          this.$emit('cropSuccess', imgInfo)
          this.pShown = false
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.dialog {
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  padding-top: 20px;

  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      min-width: 400px;
      height: 400px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .preview {
      overflow: hidden;
      // border:1px solid #67c23a;
      border:1px solid #e0e0e0;
      background: #fff;
    }

    .result-title {
      padding-top: 5px;
    }
  }
}
.footer-btn {
  margin-top: 20px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    // padding-right: 10px;

    .label-btn {
      outline: none;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      -webkit-transition: .1s;
      transition: .1s;
      font-weight: 500;
      padding: 8px 15px;
      font-size: 12px;
      border-radius: 3px;
      color: #fff;
      background-color: #409EFF;
      border-color: #409EFF;
      margin-right: 10px;
    }
    .btn {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
