import axios from './api.request'

/**
 * 获取分类树形结构
 */
export const getDataIndex = () => {
  return axios.request({
    url: 'Data/dataCategoryIndex',
    method: 'get'
  })
}

/**
 * 获取分类树形结构
 */
export const getDataCategoryTree = () => {
  return axios.request({
    url: 'Data/dataCategoryTree',
    method: 'get'
  })
}

/**
 * 获取分类列表
 */
export const getDataCategoryList = (pid) => {
  return axios.request({
    url: 'Data/dataCategoryList',
    params: { pid },
    method: 'get'
  })
}

/**
 * 获取资料列表
 */
export const getDataGroup = (params) => {
  return axios.request({
    url: 'Data',
    params,
    method: 'get'
  })
}

/**
 * 获取资料列表
 */
export const getDataRecordList = (params) => {
  return axios.request({
    url: 'Data/dataRecordList',
    params,
    method: 'get'
  })
}

/**
 * 获取资料详情
 */
export const getDataRecord = (params) => {
  return axios.request({
    url: 'Data/dataRecord',
    params,
    method: 'get'
  })
}

/**
 * 删除资料
 */
export const deleteDataRecord = (params) => {
  return axios.request({
    url: 'Data/delete',
    data: params,
    method: 'post'
  })
}

/**
 * 获取上传资料信息所需数据
 */
export const getInitData = () => {
  return axios.request({
    url: 'Data/initData',
    method: 'get'
  })
}

/**
 * 发布资料、编辑已发布的资料
 */
export const addOrEditData = (fileDes, params) => {
  return axios.uploadFile('Data/add', fileDes, params)
}

/**
 * 下载资料的压缩包
 */
export const downloadDataZip = (params) => {
  return axios.request({
    url: 'Data/download',
    responseType: 'blob',
    params,
    method: 'get'
  })
}
