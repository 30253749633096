<template>
  <div class="container">
    <div class="header">
      <div class="img-box">
        <viewer>
          <Avatar class="u-avatar" shape="square" size="128" icon="ios-person" :src="userAvatar"/>
        </viewer>
        <!-- <img class="qrcode" :src="userInfo.qrcode" alt="" /> -->
      </div>
      <div class="user-info">
        <div class="name__panel">
          <div>
            <div class="name">{{userInfo.real_name}}</div>
            <div class="layout-row">
              <div class="user_des">{{userInfo.vip_info.name}}</div>
              <template v-if="userInfo.vip_info.expiry_date">
                <div style="margin-left: 15px;">
                  <span>有效期至：</span>
                  <span>{{userInfo.vip_info.expiry_date}}</span>
                </div>
                <a class="a-btn" style="margin-left: 30px;" @click="onBuyVip(userInfo.vip_info.level)">续费</a>
              </template>
              <a v-if="userInfo.vip_info.level == 0" class="a-btn" @click="onBuyVip(0)">成为vip会员</a>
              <a v-else-if="userInfo.vip_info.level == 1" class="a-btn" @click="onBuyVip(2)">升级成为高级会员</a>
            </div>
          </div>
          <Button type="default" @click="onEditInfo">编辑个人资料</Button>
        </div>
        <div class="info-box">
          <table class="table__panel" border="0" cellspacing="0">
            <colgroup>
              <col width="90">
              <col width="220">
              <col width="120">
              <col>
            </colgroup>
            <tr>
              <td><div class="label">账号/手机号：</div></td>
              <td><div class="value">{{userInfo.mobile}}</div></td>
              <!-- <td><div class="value">{{userInfo.username}}</div></td> -->
              <td><div class="label">注册时间：</div></td>
              <td><div class="value">{{userInfo.create_date}}</div></td>
              <!-- <td><div class="label">昵称：</div></td>
              <td><div class="value">{{userInfo.nickname}}</div></td> -->
            </tr>
            <tr>
              <!-- <td><div class="label">手机号码：</div></td>
              <td><div class="value">{{userInfo.mobile}}</div></td> -->
              <td><div class="label">性别：</div></td>
              <td><div class="value">{{userInfo.gender == 1 ? '男' : '女'}}</div></td>
              <td><div class="label">电子邮箱：</div></td>
              <td><div class="value">{{userInfo.email}}</div></td>
            </tr>
            <tr>
              <td><div class="label">城市：</div></td>
              <td><div class="value">{{userInfo.province}}-{{userInfo.city}}</div></td>
              <td><div class="label">公司：</div></td>
              <td><div class="value">{{userInfo.company}}</div></td>
            </tr>
            <tr>
              <td><div class="label">行业：</div></td>
              <td><div class="value">{{userInfo.industry}}</div></td>
            </tr>
          </table>
          <viewer>
            <img class="qrcode" :src="userInfo.qrcode !== null && userInfo.qrcode !== '' ? userInfo.qrcode : nopic" alt="" />
          </viewer>
        </div>
      </div>
    </div>
    <Menu mode="horizontal" theme="light" :active-name="menuActive" @on-select="onSelectMenu">
      <MenuItem name="3">发布的供需</MenuItem>
      <MenuItem name="4">发布的公示</MenuItem>
      <MenuItem name="1">做资料</MenuItem>
      <MenuItem name="2">参与做资料</MenuItem>
      <MenuItem name="0">共享资料</MenuItem>
      <MenuItem name="5">购买记录</MenuItem>
      <MenuItem name="6">推广用户</MenuItem>
    </Menu>
    <!-- 个人共享资料 -->
    <div v-if="this.menuActive == '0' && dataCategoryTreeData !== null" :key="0" class="data__panel">
      <div class="left__bd">
        <Card class="card__dataCategory" shadow><!-- bordered -->
          <div slot="title" class="card__header">
            <div class="title" style="flex: 1;">资料分类</div>
          </div>
          <Tree :data="dataCategoryTreeData" :multiple="false" show-checkbox check-directly check-strictly @on-check-change="onDataCategoryTreeCheckChange"></Tree>
        </Card>
      </div>
      <div v-if="curSelectDataCategory" class="right__bd">
        <Card class="right-card" shadow>
          <div slot="title" class="card__header">
            <div class="title right">{{rightDataCategoryTitle}}</div>
            <Button class="btn" @click="onAddDataRecord(curSelectDataCategory)" type="primary" ghost style="margin-left: 20px;">添加资料</Button>
          </div>
          <Table class="table" ref="table" :columns="columns" :data="table.rows" :loading="table.loading">
            <div slot-scope="{row}" slot="image">
              <viewer>
                <img class="list-img" :src="row.image" @error="row.image = nopic" />
              </viewer>
            </div>
            <div slot-scope="{row}" slot="creater">{{row.username}}</div>
            <template slot-scope="{index, row}" slot="action">
              <Button @click.stop.prevent="onDataRecordItemClick(row.id)" type="primary" size="small" style="margin-right: 5px;">查看</Button>
              <Button @click="onEditDataRecord(index, row)" type="primary" size="small" style="margin-right: 5px;">编辑</Button>
              <Button @click="onDeleteDataRecord(index, row)" type="error" size="small" style="">删除</Button>
            </template>
          </Table>
        </Card>
      </div>
    </div>
    <!-- 共享资料/参与的资料 -->
    <Table v-else-if="this.menuActive == '1' || this.menuActive == '2'" :key="12" ref="table" class="table" :columns="columns" :data="table.rows" :loading="table.loading">
      <template slot-scope="{row}" slot="status">
        <div v-if="row.status == 0">
          <span class="status__dot red"></span><span class="status__text">未签名</span>
        </div>
        <div v-else>
          <span class="status__dot"></span><span class="status__text">已签名</span>
        </div>
      </template>
      <template slot-scope="{index, row}" slot="action">
        <Button @click="onViewContractRow(index, row)" type="primary" size="small">查看</Button>
        <Button v-if="menuActive == '1'" @click="onDeleteContractRow(index, row)" type="error" size="small" style="margin-left:10px">删除</Button>
      </template>
    </Table>
    <!-- 发布的供需 -->
    <div v-else-if="menuActive == '3'" class="demand__panel" :key="3">
      <template v-if="table.totalCount > 0">
        <div v-for="(item, index) in table.rows" :key="item.id" class="demand-box"><!-- @click="onDemandItemClick(item.id)" -->
          <!-- <viewer>
            <img class="img" :src="item.image" @error="item.image = nopic" />
          </viewer> -->
          <div class="info-panel">
            <div class="top">
              <div class="title">{{item.title}}</div>
              <!-- <div class="create"><span>{{item.create_date}}</span></div> -->
            </div>
            <!-- <div class="detail">{{item.detail}}</div> -->
            <div class="bottom">
              <div class="expire-date"><span>{{item.create_date}}</span></div>
              <!-- <div v-if="item.expire_date !== null" class="expire-date">截至日期：<span>{{dateFormat("YYYY-mm-dd", new Date(item.expire_date))}}</span></div>
              <div v-else class="expire-date"></div>
              <div>
                <span>联系人：</span><span class="contact">{{item.contact}}</span>
                <span style="margin-left: 20px;">联系电话：</span><span class="contact">{{item.phone}}</span>
              </div> -->
            </div>
          </div>
          <div class="action-panel" style="width: 170px;">
            <div style="margin-bottom: 5px;">
              <!-- 状态： -->
              <div v-if="item.status == 0">
                <span class="status__dot gray"></span><span class="status__text">待审核</span>
              </div>
              <div v-else-if="item.status == 1">
                <p v-if="item.handle_status === 0"><span class="status__dot"></span><span class="status__text">进行中</span></p>
                <p v-else><span class="status__dot"></span><span class="status__text">已完成</span></p>
                <!-- <span class="status__dot"></span><span class="status__text">已通过</span> -->
              </div>
              <div v-else-if="item.status == 2">
                <span class="status__dot red"></span><span class="status__text">不通过</span>
              </div>
            </div>
            <div>
              <Button @click.stop.prevent="onDemandItemClick(item.id)" type="primary" size="small">查看</Button>
              <Button v-if="item.status === 1 && item.handle_status === 0" @click.stop.prevent="onHandleDemandItemClick(item.id)" type="primary" size="small" style="margin-left:10px">处理</Button>
              <!-- <Button @click.stop.prevent="onEditDemandRow(index, item)" type="primary" size="small" style="margin-left:10px">编辑</Button> -->
              <Button @click.stop.prevent="onDeleteDemandRow(index, item)" type="error" size="small" style="margin-left:10px">删除</Button>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="nodata-tip">暂无数据</div>
      <Spin v-if="table.loading" fix>加载中...</Spin>
    </div>
    <!-- 发布的公示 -->
    <Table v-else-if="this.menuActive == '4'" :key="4" ref="table" class="table" :columns="columns" :data="table.rows" :loading="table.loading">
      <template slot-scope="{row}" slot="status">
        <div v-if="row.status == 0">
          <span class="status__dot gray"></span><span class="status__text">待审核</span>
        </div>
        <div v-else-if="row.status == 1">
          <span class="status__dot"></span><span class="status__text">已通过</span>
        </div>
        <div v-else-if="row.status == 2">
          <span class="status__dot red"></span><span class="status__text">不通过</span>
        </div>
      </template>
      <template slot-scope="{index, row}" slot="action">
        <Button @click="onViewPublicityRow(index, row)" type="primary" size="small">查看</Button>
        <!-- 不能删除 -->
        <!-- <Button @click="onDeletePublicityRow(index, row)" type="error" size="small" style="margin-left:10px">删除</Button> -->
      </template>
    </Table>
    <!-- 购买记录/推广用户 -->
    <Table v-else-if="this.menuActive == '5' || this.menuActive == '6'" :key="56" ref="table" class="table" :columns="columns" :data="table.rows" :loading="table.loading" />
    <!-- 分页 -->
    <Page v-if="table.totalCount > table.pageSize" :total="table.totalCount" show-total :page-size="table.pageSize" :current="table.currentPage" @on-change="onPageChange" style="margin-top:5px;" />
    <EditUserInfo v-model="showEditUser" :tab-name="editUserInfoTabName" />
    <AddDemand v-model="showAddDemand" :demand-info="demandInfo" @on-success="onAddDemandSuccess"/>
    <AddDataRecord v-model="showAddDataRecord" :data-record-info="dataRecordInfo" @on-success="onAddDataRecordSuccess"/>
    <PublicityDetail v-model="publicityInfo" />
    <Modal
        v-model="isShowHandleDemand"
        title="提示"
        @on-ok="handleDemand"
        @on-cancel="isShowHandleDemand = false">
        <p>确认已完成？</p>
    </Modal>
  </div>
</template>

<script>
import EditUserInfo from '@/components/EditUserInfo.vue'
import AddDemand from '@/components/AddDemand.vue'
import { getDataCategoryTree, getDataRecordList, deleteDataRecord } from '@/api/data.js'
import AddDataRecord from '@/components/AddDataRecord'
import { getContractList, deleteContract } from '@/api/contract.js'
import { getMyDemandList, handleDemand, deleteMyDemand } from '@/api/demand.js'
import { getPublicityList, deletePublicity } from '@/api/publicity.js'
import PublicityDetail from '@/components/PublicityDetail.vue'
import { getOrderList, getMyInviteUser } from '@/api/user.js'
import { mapGetters, mapActions } from 'vuex'
import { dateFormat } from '@/libs/util.js'

export default {
  name: 'User',
  components: {
    EditUserInfo,
    AddDemand,
    AddDataRecord,
    PublicityDetail
  },
  data() {
    return {
      nopic: require('@/assets/images/nopic.png'),

      menuActive: '3',
      table: {
        loading: false,
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
        rows: []
      },

      showEditUser: false,
      editUserInfoTabName: null,

      /* 资料 */
      dataCategoryTreeData: null,
      curSelectDataCategory: null,

      showAddDataRecord: false,
      dataRecordInfo: null,

      /* 资料 */
      contractInfo: null,
      showContract: false,

      /* 供需 */
      showAddDemand: false,
      demandInfo: null,
      isShowHandleDemand: false,
      currentDemandId: null,

      /* 公示 */
      publicityInfo: null
    }
  },
  computed: {
    ...mapGetters(['userAvatar', 'userInfo']),
    columns() {
      if (this.menuActive == '0') {
        return [{
          type: 'index',
          title: '序号',
          width: 60,
          align: 'center'
        }, {
          key: 'status',
          title: '状态',
          width: 80,
          render(h, params) {
            let ret = '未知'
            switch (params.row.status) {
              case null:
                ret = '无需审核'
                break
              case 0:
                ret = '待审核'
                break
              case 1:
                ret = '已通过'
                break
              case 2:
                ret = '不通过'
                break
            }
            return h('div', ret)
          }
        },
        // {
        //   slot: 'image',
        //   title: '列表图片',
        //   width: 100,
        //   align: 'center'
        // },
        {
          key: 'title',
          title: '标题'/* ,
          minWidth: 300 */
        },
        // {
        //   key: 'city_name',
        //   title: '城市',
        //   width: 80
        // },
        {
          key: 'area',
          title: '城市',
          width: 80,
          render(h, params) {
            return h('div', params.row.city_id !== null ? params.row.city_name : '全国')
          }
        },
        /* {
          key: 'share_code',
          title: '分享码',
          width: 80
        }, {
          key: 'is_publicity',
          title: '公开性',
          width: 80,
          render(h, params) {
            return h('div', params.row.is_publicity === 1 ? '公开' : '私密')
          }
        },
        {
          key: 'is_premium',
          title: '收费模式',
          width: 70,
          render(h, params) {
            return h('div', params.row.is_premium === 1 ? '收费' : '免费')
          }
        },{
          key: 'create_date',
          title: '创建时间',
          width: 170
        }, {
          slot: 'creater',
          title: '发布人',
          minWidth: 100
        }, */
        {
          slot: 'action',
          title: '操作',
          fixed: 'right',
          width: 190
        }]
      } else if (this.menuActive == '1' || this.menuActive == '2') {
        const actionWidth = 135; let timeKey = 'create_date'; let timeLable = '创建时间'
        if (this.menuActive == '2') {
          timeKey = 'join_date'; timeLable = '加入时间'
        }
        return [{
          type: 'index',
          title: '序号',
          width: 60,
          align: 'center'
        }, {
          key: 'name',
          title: '资料名称',
          minWidth: 200,
          align: 'left'
        }, {
          key: timeKey,
          title: timeLable,
          width: 180,
          align: 'left'
        },
        // {
        //   slot: 'status',
        //   title: '资料状态',
        //   width: 100,
        //   align: 'center'
        // },
        {
          slot: 'action',
          title: '操作',
          width: actionWidth,
          align: 'center'
        }]
      } else if (this.menuActive == '4') {
        return [{
          type: 'index',
          title: '序号',
          width: 60,
          align: 'center'
        }, {
          key: 'create_date',
          title: '创建时间',
          width: 180,
          align: 'left'
        }, {
          key: 'title',
          title: '项目名称',
          minWidth: 200,
          align: 'left'
        }, {
          slot: 'status',
          title: '审核状态',
          width: 120,
          align: 'center'
        }, {
          slot: 'action',
          title: '操作',
          width: 135,
          align: 'center'
        }]
      } else if (this.menuActive == '5') {
        return [{
          type: 'index',
          title: '序号',
          width: 60,
          align: 'center'
        }, {
          key: 'order_no',
          title: '订单号',
          width: 130
        }, {
          key: 'pay_date',
          title: '购买时间',
          width: 180,
          align: 'left'
        }, {
          key: 'product_des',
          title: '订单类型',
          minWidth: 200,
          align: 'left'
        }, {
          key: 'order_amount',
          title: '订单金额',
          width: 120,
          align: 'center'
        }]
      } else if (this.menuActive == '6') {
        return [{
          type: 'index',
          title: '序号',
          width: 60,
          align: 'center'
        }, {
          key: 'create_date',
          title: '注册时间',
          width: 180
        }, {
          key: 'real_name',
          title: '姓名',
          minWidth: 120
        }, {
          key: 'mobile',
          title: '手机号',
          minWidth: 125
        }, {
          key: 'city',
          title: '所在城市',
          minWidth: 100
        }]
      }
      return []
    },
    rightDataCategoryTitle() {
      if (this.curSelectDataCategory) {
        return this.curSelectDataCategory.title
      }
      return null
    }
  },
  watch: {
    /* 资料 */
    curSelectDataCategory(newVal) {
      this.table.currentPage = 1
      this.table.totalCount = 0
      this.table.rows = []
      if (newVal) {
        this.getDataRecordData()
      } else {
        if (this.$refs.table) {
          this.$refs.table.selectAll(false)
        }
      }
    },
    /* 供需 */
    showAddDemand(newVal) {
      if (!newVal) {
        this.demandInfo = null
      }
    }
  },
  async mounted() {
    await this.getUserInfo()
    // this.getContractData()
    await this.getDataCategoryTree()
    await this.getDemandListData()
    // 跳转后切换标签页
    if (this.$route.query.menuActive !== undefined) {
      this.onSelectMenu(this.$route.query.menuActive)
    }
    if (this.$route.query.isOpenEditInfo !== undefined) {
      if (this.$route.query.tabName !== undefined) {
        this.editUserInfoTabName = this.$route.query.tabName
      }
      this.showEditUser = true
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    dateFormat,
    /* 共享资料 开始 */
    async getDataCategoryTree() {
      try {
        // 获取分类树形数据
        const res = await getDataCategoryTree()
        this.dataCategoryTreeData = [{id: 0, title: '全部', checked: true}].concat(res.content.rows)
        this.curSelectDataCategory = this.dataCategoryTreeData[0]
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    async getDataRecordData(create_flag = 'y', start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }
      const params = { create_flag, start, count: this.table.pageSize, status: -1 }
      if (this.curSelectDataCategory) {
        params.data_category_id = this.curSelectDataCategory.id
      }

      try {
        const res = await getDataRecordList(params)
        if (this.$refs.table) {
          this.$refs.table.selectAll(false)
        }
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    // 清除树形控件的状态
    cleanDataCategoryTreeData(dataCategoryTreeData) {
      const len = dataCategoryTreeData ? dataCategoryTreeData.length : 0
      for (let i = 0; i < len; i++) {
        const item = dataCategoryTreeData[i]
        this.$set(item, 'expand', false)
        this.$set(item, 'checked', false)
        this.cleanDataCategoryTreeData(item.children)
      }
    },
    // 展开树形控件的节点
    expandDataCategoryTreeNode(node) {
      this.$set(node, 'expand', true)
      const parent = this.findDataCategoryTreeNode(this.dataCategoryTreeData, node.pid)
      if (parent) {
        return this.expandDataCategoryTreeNode(parent)
      }
    },
    // 查找树形控件的节点
    findDataCategoryTreeNode(dataCategoryTreeData, targetID) {
      const len = dataCategoryTreeData ? dataCategoryTreeData.length : 0
      let $node = null
      for (let i = 0; i < len; i++) {
        if (dataCategoryTreeData[i].id == targetID) {
          return dataCategoryTreeData[i]
        }
        $node = this.findDataCategoryTreeNode(dataCategoryTreeData[i].children, targetID)
        if ($node) {
          break
        }
      }
      return $node
    },
    onDataCategoryTreeCheckChange(_, cur) {
      this.cleanDataCategoryTreeData(this.dataCategoryTreeData)
      // selections.forEach((item) => {
      //   this.$set(item, 'checked', false)
      // })

      // 只选中最后一次选中的
      this.$set(cur, 'checked', true)
      this.expandDataCategoryTreeNode(cur)

      this.curSelectDataCategory = cur
      console.log('onDataCategoryTreeCheckChange: ', this.curSelectDataCategory)
    },
    onAddDataRecord(pDataCategory) {
      if (pDataCategory) {
        this.dataRecordInfo = {
          c_id: pDataCategory.id,
          is_add: 1
        }
      } else {
        this.dataCategoryInfo = null
      }
      this.showAddDataRecord = true
    },
    onAddDataRecordSuccess(detail) {
      const {dataRecordInfo, isEdit} = detail
      this.table.currentPage = 1
      this.getDataRecordData('y', null, true)
    },
    onEditDataRecord(index, row) {
      this.dataRecordInfo = Object.assign({index}, row)
      this.showAddDataRecord = true
    },
    onDeleteDataRecord(index, row) {
      const content = '<p>确定删除这条资料？</p><p style="color:#c00;">注意：删除后不可以恢复</p>'
      this.$Modal.confirm({
        title: '提示',
        content,
        onOk: async () => {
          try {
            this.$refs.table.selectAll(false)

            const params = { ids: JSON.stringify([row.id]) }

            await deleteDataRecord(params)
            let maxPage = parseInt(this.table.totalCount / this.table.pageSize)
            const remind = this.table.totalCount % this.table.pageSize
            if (remind !== 0) maxPage++

            // 最后一页
            if (this.table.currentPage === maxPage) {
              if (this.table.currentPage > 1 && remind == 1) {
                this.table.currentPage -= 1
                this.getDataRecordData(null, true)
              } else {
                this.table.totalCount -= 1
                this.table.rows.splice(index, 1)
              }
            } else {
              this.getDataRecordData(null, true)
            }

            this.$Message.success('删除成功')
          } catch (err) {
            this.$Message.error(err.message)
          }
        }
      })
    },
    onDataRecordItemClick(id) {
      const routeData = this.$router.resolve({ name: 'DataRecordDetail', query: {id} })
      window.open(routeData.href, '_blank')
    },
    /* 共享资料 结束 */
    /* 资料 开始 */
    async getContractData(create_flag = 'y', start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }

      const params = {create_flag, start, count: this.table.pageSize }

      try {
        const res = await getContractList(params)
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    onViewContractRow(index, row) {
      // this.contractInfo = row
      // this.showContract = true
      // 新开页面
      // const newPage = this.$router.resolve({name: 'contract', params: {id: row.id}})
      // window.open(newPage.href, '_blank')
      this.$router.push({name: 'contract', params: {id: row.id}})
    },
    onDeleteContractRow(index, row) {
      const content = `<p>确定删除资料：${row.name}？</p><p style="color:#c00;">注意：删除后不可以恢复</p>`
      this.$Modal.confirm({
        title: '提示',
        content,
        onOk: async () => {
          try {
            const params = { ids: JSON.stringify([row.id]) }
            await deleteContract(params)

            let maxPage = parseInt(this.table.totalCount / this.table.pageSize)
            const remind = this.table.totalCount % this.table.pageSize
            if (remind !== 0) maxPage++

            // 最后一页
            if (this.table.currentPage === maxPage) {
              if (this.table.currentPage > 1 && remind == 1) {
                this.table.currentPage -= 1
                this.getContractData('y', null, true)
              } else {
                this.table.totalCount -= 1
                this.table.rows.splice(index, 1)
              }
            } else {
              this.getContractData('y', null, true)
            }

            this.$Message.success('删除成功')
          } catch (err) {
            this.$Message.error(err.message)
          }
        }
      })
    },
    /* 资料 结束 */
    /* 供需 开始 */
    async getDemandListData(start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }

      const params = {start, count: this.table.pageSize }

      try {
        const res = await getMyDemandList(params)
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    onDemandItemClick(id) {
      const routeData = this.$router.resolve({ name: 'DemandDetail', query: {id} })
      window.open(routeData.href, '_blank')
    },
    onHandleDemandItemClick(id) {
      this.isShowHandleDemand = true
      this.currentDemandId = id
    },
    async handleDemand() {
      const res = await handleDemand({id: this.currentDemandId})
      this.isShowHandleDemand = false
      this.$Message.success('处理成功！')
      this.getDemandListData(null, true)
    },
    onAddDemandSuccess(detail) {
      if (detail.isEdit) {
        const demandInfo = detail.demandInfo
        if (detail.index < this.table.rows.length && demandInfo.id == this.table.rows[detail.index].id) {
          this.$set(this.table.rows, detail.index, demandInfo)
        } else {
          this.getDemandListData(null, false)
        }
      }
    },
    onEditDemandRow(index, row) {
      this.demandInfo = Object.assign({index}, row)
      this.showAddDemand = true
    },
    onDeleteDemandRow(index, row) {
      const content = '<p>确定删除这条供需信息？</p><p style="color:#c00;">注意：删除后不可以恢复</p>'
      this.$Modal.confirm({
        title: '提示',
        content,
        onOk: async () => {
          try {
            const params = { ids: JSON.stringify([row.id]) }
            await deleteMyDemand(params)

            let maxPage = parseInt(this.table.totalCount / this.table.pageSize)
            const remind = this.table.totalCount % this.table.pageSize
            if (remind !== 0) maxPage++

            // 最后一页
            if (this.table.currentPage === maxPage) {
              if (this.table.currentPage > 1 && remind == 1) {
                this.table.currentPage -= 1
                this.getDemandListData(null, true)
              } else {
                this.table.totalCount -= 1
                this.table.rows.splice(index, 1)
              }
            } else {
              this.getDemandListData(null, true)
            }

            this.$Message.success('删除成功')
          } catch (err) {
            this.$Message.error(err.message)
          }
        }
      })
    },
    /* 供需 结束 */
    /* 公示 开始 */
    async getPublicityListData(start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }
      const params = {start, count: this.table.pageSize, type: 'my'}
      try {
        const res = await getPublicityList(params)
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    onViewPublicityRow(index, row) {
      // this.publicityInfo = {id: row.id, title: row.title}
      const routeData = this.$router.resolve({ name: 'PublicityDetail', query: {id: row.id} })
      window.open(routeData.href, '_blank')
    },
    onDeletePublicityRow(index, row) {
      const content = '<p>确定删除这条公示？</p><p style="color:#c00;">注意：删除后不可以恢复</p>'
      this.$Modal.confirm({
        title: '提示',
        content,
        onOk: async () => {
          try {
            await deletePublicity(row.id)

            let maxPage = parseInt(this.table.totalCount / this.table.pageSize)
            const remind = this.table.totalCount % this.table.pageSize
            if (remind !== 0) maxPage++

            // 最后一页
            if (this.table.currentPage === maxPage) {
              if (this.table.currentPage > 1 && remind == 1) {
                this.table.currentPage -= 1
                this.getPublicityListData(null, true)
              } else {
                this.table.totalCount -= 1
                this.table.rows.splice(index, 1)
              }
            } else {
              this.getPublicityListData(null, true)
            }

            this.$Message.success('删除成功')
          } catch (err) {
            this.$Message.error(err.message)
          }
        }
      })
    },
    /* 公示 结束 */
    /* 购买记录 开始 */
    async getOrderListData(start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }
      const params = {start, count: this.table.pageSize}
      try {
        const res = await getOrderList(params)
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    /* 购买记录 结束 */
    /* 推广用户 开始 */
    async getInviteUserData(start = null, showLoading = false) {
      if (showLoading || this.table.totalCount == 0) {
        this.table.loading = true
      }
      if (start == null) {
        start = (this.table.currentPage - 1) * this.table.pageSize
      }
      const params = {start, count: this.table.pageSize}
      try {
        const res = await getMyInviteUser(params)
        this.table.rows = res.content.rows
        this.table.totalCount = parseInt(res.content.total_count)
      } catch (error) {
        this.$Message.error(error.message)
      }
      this.table.loading = false
    },
    /* 推广用户 结束 */
    onEditInfo() {
      this.showEditUser = true
    },
    onSelectMenu(name) {
      this.menuActive = name
      Object.assign(this.$data.table, this.$options.data.call(this).table)
      if (this.menuActive == '0') {
        this.getDataRecordData()
      } else if (this.menuActive == '1') {
        this.getContractData()
      } else if (this.menuActive == '2') {
        this.getContractData('n')
      } else if (this.menuActive == '3') {
        this.getDemandListData()
      } else if (this.menuActive == '4') {
        this.getPublicityListData()
      } else if (this.menuActive == '5') {
        this.getOrderListData()
      } else if (this.menuActive == '6') {
        this.getInviteUserData()
      }
    },
    onPageChange(val) {
      this.table.currentPage = val
      if (this.menuActive == '0') {
        this.getDataRecordData('y', null, true)
      } else if (this.menuActive == '1') {
        this.getContractData('y', null, true)
      } else if (this.menuActive == '2') {
        this.getContractData('n', null, true)
      } else if (this.menuActive == '3') {
        this.getDemandListData(null, true)
      } else if (this.menuActive == '4') {
        this.getPublicityListData(null, true)
      } else if (this.menuActive == '5') {
        this.getOrderListData(null, true)
      } else if (this.menuActive == '6') {
        this.getInviteUserData(null, true)
      }
    },
    onBuyVip(level) {
      this.$router.push({name: 'member', params: {level}})
    }
  }
}
</script>

<style lang="less" scoped>

.container {
  // margin: 20px auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  min-height: 800px;
}

.nodata-tip {
  padding: 30px 10px;
}

.header {
  display: flex;
  width: 100%;
  padding-bottom: 20px;

  .img-box {
    display: flex;
    flex-direction: column;

    .qrcode {
      width: 128px;
      height: 128px;
      margin-top: 10px;
      border-radius: 3px;
    }
  }
  .user-info {
    margin-left: 20px;
    flex: 1;
  }
}

.name__panel {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #efefef;
  .name {
    font-size: 28px;
    line-height: 40px;
    color: #000
  }
  .user_des {
    color: #c00;
    border: solid 1px #c00;
    padding: 0 5px;
  }
  .a-btn {
    font-size: 15px;
    color:#4183c4;
    margin-left: 20px;
    &:hover{
      filter: opacity(0.6);
    }
  }
}

.info-box {
  display: flex;

  .qrcode {
    width: 128px;
    height: 128px;
    margin-top: 10px;
    border-radius: 3px;
  }
}
.table__panel {
  position: relative;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-color: black;
  tr {
    height: 30px;
  }
}

.table__panel td .label {
  // text-align: left;
  text-align: right;
}

.table__panel td .value {
  text-align: left;
  margin-left: 20px;
}

.u-avatar ::v-deep img {
  object-fit: cover;
}

.status__dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
  background-color: #52c41a;
}

.status__dot.red {
  background-color: #c00;
}

.status__dot.gray {
  background-color: #999;
}

.status__text {
  display: inline-block;
  margin-left: 5px;
}

.data__panel {
  min-height: 500px;
  display: flex;
  height: 100%;

  ::v-deep .ivu-card-head {
    padding: 5px 16px;
  }

  .left__bd {
    position: relative;
    width: 220px;
    height: 100%;
    flex-shrink: 0;

    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }

  .card__header {
    position: relative;
    height: 41px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #17233d;
      &.right {
        width: 100px;
      }
    }
    .btn {
      margin-left: 10px;
    }
  }

  .card__data-category {
    // width: 100%;
    height: 100%;
  }

  .card__data-category ::v-deep .ivu-card-body {
    // position: absolute;
    // top: 52px;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow-y: auto;
    // box-sizing: border-box;
    // padding: 5px 16px 10px;
  }

  .card__data-category ::v-deep .ivu-checkbox-inner {
    border-radius: 50%;
  }

  .right__bd {
    // position: relative;
    flex: 1;
    // min-width: 900px;
    // width: 100%;
    // height: 100%;
    // box-sizing: border-box;
    // margin-left: 20px;
  }

  .right-card {
    width: 100%;
    // height: 100%;
  }

  .right-card ::v-deep .ivu-card-body {
    // position: absolute;
    // top: 52px;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // padding: 0;
  }

  .list-img {
    width: 64px;
    height: 64px;
    border-radius: 5px;
    margin-top: 6px;
    object-fit: cover;
  }
}

.demand__panel {
  position: relative;
  background-color: white;
}

.demand-box {
  display: flex;
  // height: 140px;
  height: 70px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px #dbdde1;
  .img {
    // width: 100px;
    // height: 100px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    object-fit: cover;
    margin-right: 10px;
  }
  .info-panel {
    flex: 1;
    // height: 100px;
    height: 50px;
    overflow: hidden;

    .top {
      display: flex;
      .title {
        flex: 1;
        color: #000;
        font-size: 18px;
        height: 25px;
        line-height: 25px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .create {
        color: #999;
        padding-left: 5px;
      }
    }
    .detail {
      margin-top: 5px;
      color: #888;
      font-size: 15px;
      line-height: 20px;
      height: 40px;

      overflow: hidden;
      word-break: break-all;  /* break-all(允许在单词内换行。) */
      text-overflow: ellipsis;  /* 超出部分省略号 */
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 2; /** 显示的行数 **/
    }
    .bottom {
      display: flex;
      // margin-top: 10px;
      margin-top: 5px;
      height: 20px;
      justify-content: space-between;
      color: #666;
      .expire-date {
        color: #999;
      }
      .contact {
        color: #2e86b9
      }
    }
  }
  .action-panel {
    flex-shrink: 0;
    margin-left: 20px;
  }
}

::v-deep .ivu-page {
  padding: 10px 0;
  text-align: center;
}
</style>
