import axios from './api.request'

/**
 * 获取 Demand 页面数据
 */
export const getDemandGroup = (params) => {
  return axios.request({
    url: 'Demand/homeIndex',
    params,
    method: 'get'
  })
}

/**
 * 获取 Demand 页面数据
 */
export const getDemandIndex = (params) => {
  return axios.request({
    url: 'Demand/index',
    params,
    method: 'get'
  })
}

/**
 * 获取 Demand 列表数据
 */
export const getDemandList = (params) => {
  return axios.request({
    url: 'Demand/demandList',
    params,
    method: 'get'
  })
}

/**
 * 获取单个 Demand 数据
 */
export const getDemand = (params) => {
  return axios.request({
    url: 'Demand/demand',
    params,
    method: 'get'
  })
}

/**
 * 获取发布供需信息所需数据
 */
export const getInitData = () => {
  return axios.request({
    url: 'Demand/initData',
    method: 'get'
  })
}

/**
 * 发布供需、编辑已发布的供需
 */
export const addOrEditDemand = (fileDes, params) => {
  return axios.uploadFile('Demand/add', fileDes, params)
}

/**
 * 获取我发布的供需
 */
export const getMyDemandList = (params) => {
  return axios.request({
    url: 'Demand/myPosted',
    params,
    method: 'get'
  })
}

/**
 * 审核公示信息
 */
export const handleDemand = (params) => {
  return axios.request({
    url: 'Demand/handle',
    data: params,
    method: 'post'
  })
}

/**
 * 删除我发布的供需
 */
export const deleteMyDemand = (params) => {
  return axios.request({
    url: 'Demand/deleteMyPosted',
    data: params,
    method: 'post'
  })
}
